import { generatePath } from 'react-router'

import type { DrawerTab, DrawerType } from './components/domain/OkrDrawer/types'
import { configureEndpoint } from './config'
import { isTrueString } from './lib/boolean'
import { getCurrentTermId } from './lib/domain/okrTerm'

// 外部サイトのURL
export const ExternalUrls = {
  // ヘルプページ：1on1使い方ガイド -基本編-
  ONE_ON_ONE_USER_GUIDE:
    'https://support.resily.com/hc/ja/articles/22081751291161?utm_source=resily&utm_medium=referral&utm_campaign=help',
  // ヘルプページ：1on1の閲覧権限について
  ONE_ON_ONE_VIEW_PERMISSION:
    'https://support.resily.com/hc/ja/articles/5851201343257?utm_source=resily&utm_medium=referral&utm_campaign=help',
  // 1on1スタートガイド第1回（全5回）：1on1そのものについて話してみる
  ONE_ON_ONE_START_GUIDE_1:
    'https://support.resily.com/hc/ja/articles/4659068761625-1on1%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%AC%E3%82%A4%E3%83%89%E7%AC%AC1%E5%9B%9E-%E5%85%A85%E5%9B%9E-1on1%E3%81%9D%E3%81%AE%E3%82%82%E3%81%AE%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E8%A9%B1%E3%81%97%E3%81%A6%E3%81%BF%E3%82%8B',
  // 1on1スタートガイド第2回（全5回）：一緒に振り返りをしてみる
  ONE_ON_ONE_START_GUIDE_2:
    'https://support.resily.com/hc/ja/articles/4662937405209-1on1%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%AC%E3%82%A4%E3%83%89%E7%AC%AC2%E5%9B%9E-%E5%85%A85%E5%9B%9E-%E4%B8%80%E7%B7%92%E3%81%AB%E6%8C%AF%E3%82%8A%E8%BF%94%E3%82%8A%E3%82%92%E3%81%97%E3%81%A6%E3%81%BF%E3%82%8B',
  // 1on1スタートガイド第3回（全5回）：曖昧なことを言語化してみる
  ONE_ON_ONE_START_GUIDE_3:
    'https://support.resily.com/hc/ja/articles/4821254092825-1on1%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%AC%E3%82%A4%E3%83%89%E7%AC%AC3%E5%9B%9E-%E5%85%A85%E5%9B%9E-%E6%9B%96%E6%98%A7%E3%81%AA%E3%81%93%E3%81%A8%E3%82%92%E8%A8%80%E8%AA%9E%E5%8C%96%E3%81%97%E3%81%A6%E3%81%BF%E3%82%8B',
  // 1on1スタートガイド第4回（全5回）：少し視野を広げてみる
  ONE_ON_ONE_START_GUIDE_4:
    'https://support.resily.com/hc/ja/articles/4821553510937-1on1%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%AC%E3%82%A4%E3%83%89%E7%AC%AC4%E5%9B%9E-%E5%85%A85%E5%9B%9E-%E5%B0%91%E3%81%97%E8%A6%96%E9%87%8E%E3%82%92%E5%BA%83%E3%81%92%E3%81%A6%E3%81%BF%E3%82%8B',
  // 1on1スタートガイド最終回（全5回）：二人にとっての1on1を話し合う
  ONE_ON_ONE_START_GUIDE_5:
    'https://support.resily.com/hc/ja/articles/4821771830553-1on1%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%AC%E3%82%A4%E3%83%89%E6%9C%80%E7%B5%82%E5%9B%9E-%E5%85%A85%E5%9B%9E-%E4%BA%8C%E4%BA%BA%E3%81%AB%E3%81%A8%E3%81%A3%E3%81%A6%E3%81%AE1on1%E3%82%92%E8%A9%B1%E3%81%97%E5%90%88%E3%81%86',
  // ヘルプサイト：固定アジェンダを編集する
  EDIT_FIXED_AGENDA:
    'https://support.resily.com/hc/ja/articles/7356628247193?utm_source=resily&utm_medium=referral&utm_campaign=help',
  // ヘルプページ
  HELP_PAGE:
    'https://support.resily.com/hc/ja?utm_source=resily&utm_medium=referral&utm_campaign=help',
  // 問い合わせページ
  INQUIRY_PAGE:
    'https://support.resily.com/hc/ja/requests/new?utm_source=resily&utm_medium=referral&utm_campaign=support',
  // セルフサーブのOrganization用の操作説明会予約ページ
  BOOK_TUTORIAL_PAGE: 'https://meetings.hubspot.com/mika-yatsunami/ss30min',
  // ユーザーCSVインポートのグループ設定方法説明ページ
  USER_CSV_EXPORT_INCLUDE_GROUP_CSV_HINT_PAGE:
    'https://support.resily.com/hc/ja/articles/4870949701785',
  // FAQ：100%目標ではだめですか？
  FAQ_WORRY_OKR: 'https://knowledge.resily.com/4c57768d45aa4bdea94a18f8e7a0f2e9?utm_source=resily',
  // 自信度機能の活用方法
  CONFIDENCE_GUIDE: 'https://support.resily.com/hc/ja/articles/900002947126?utm_source=resily',
  // CSVファイルから「グループ」を一括追加、変更、削除する
  GROUPS_WITH_CSV:
    'https://support.resily.com/hc/ja/articles/4411948524313-%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%8B%E3%82%89%E9%83%A8%E7%BD%B2%E3%82%92%E4%B8%80%E6%8B%AC%E8%BF%BD%E5%8A%A0-%E5%A4%89%E6%9B%B4-%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B',
  // CSVファイルから「ユーザー」を一括追加・変更・削除する
  USERS_WITH_CSV:
    'https://support.resily.com/hc/ja/articles/4411956040345-%E3%83%95%E3%82%A1%E3%82%A4%E3[…]%A0-%E5%A4%89%E6%9B%B4-%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B',
  // OKRにグループタグを設定する方法
  HOW_TO_SET_GROUP_TAGS_IN_OKR: 'https://support.resily.com/hc/ja/articles/900002399506',
  // MAP上のOKRをグループタグ/ユーザーで絞り込む方法
  HOW_TO_USE_MAP_FILTERS: 'https://support.resily.com/hc/ja/articles/900003481606',
  // ホームのアクティビティ画面の自分のOKRの詳細
  HOW_TO_HOME_ACTIVITY_MY_OKR:
    'https://support.resily.com/hc/ja/articles/22058985760665-%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%81%A8%E3%83%9B%E3%83%BC%E3%83%A0#h_01J0MY02YVWVX1PMFAF9KV3Q4W',
  // ホームのアクティビティ画面の子OKRの詳細
  HOW_TO_HOME_ACTIVITY_CHILD_OKR:
    'https://support.resily.com/hc/ja/articles/22058985760665-%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%81%A8%E3%83%9B%E3%83%BC%E3%83%A0#01J0MZNEZDF8X838NKFVNAJBA6',
  // 2要素認証を設定する方法
  TWO_FACTOR_AUTHENTICATION_SET_UP_GUIDE:
    'https://support.resily.com/hc/ja/articles/900002850083-2%E8%A6%81%E7%B4%A0%E8%AA%8D%E8%A8%BC%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B',
  // 管理者向けガイド
  SUPPORT_GUIDE_FOR_ADMIN: 'https://support.resily.com/hc/ja/categories/900000174326',
  // お問い合わせフォーム（セールス向け）
  CONTACT_TO_SALES: 'https://resily.com/contact/resily',
  // 利用規約
  TERM_OF_SERVICE: 'https://resily.com/terms',
  // プライバシーポリシー
  PRIVACY_POLICY: 'https://resily.com/privacy_ci',
  // Stripeの利用規約
  STRIPE_TERM_OF_SERVICE: 'https://stripe.com/jp/legal/end-users',
  // Stripeのプライバシーポリシー
  STRIPE_PRIVACY_POLICY: 'https://stripe.com/jp/privacy',
  // アクションプランの活用方法
  HOW_TO_USE_ACTION_PLAN:
    'https://support.resily.com/hc/ja/articles/900002515083?utm_source=resily&utm_medium=referral&utm_campaign=support',
  // Slackと連携する方法
  HOW_TO_INTEGRATE:
    'https://support.resily.com/hc/ja/articles/900002402266-Slack%E3%81%A8%E9%80%A3%E6%90%BA%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95',
  // 組織設定と管理者の権限
  ADMIN_PERMISSION:
    'https://support.resily.com/hc/ja/articles/900006802503-%E7%B5%84%E7%B9%94%E8%A8%AD%E5%AE%9A%E3%81%A8%E7%AE%A1%E7%90%86%E8%80%85%E3%81%AE%E6%A8%A9%E9%99%90',
  // ユーザーを追加する
  ADD_USER:
    'https://support.resily.com/hc/ja/articles/900002375503-%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B',
  // グループタグを追加する
  ADD_GROUP_TAGS:
    'https://support.resily.com/hc/ja/articles/900002868763-%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E3%82%BF%E3%82%B0',
  // ノート活用方法
  HOW_TO_USE_NOTE: 'https://support.resily.com/hc/ja/articles/900002292606',
  // OKRサンプル
  SAMPLE_OKR:
    'https://knowledge.resily.com/0b7e9ec4636142429b763693c4906176#block-9e06c4697e254a9ba4072da5f5fbf799',
  // OKR詳細モーダル(旧右ドロワー)の使い方を見る
  HOW_TO_USE_OKR_MODAL: 'https://support.resily.com/hc/ja/articles/4411045688345',
} as const

export const config = configureEndpoint({
  protocol: window.location.protocol,
  host: window.location.host,
})

export const generateUrlWithQueryParams = (
  href: string,
  ...params: ConstructorParameters<typeof URLSearchParams>
): string => `${href}?${new URLSearchParams(...params).toString()}`

export const currentTag = 'current'
// セッションストレージのCurrentTermIDと比較する
export const isInCurrentTerm = (termId: string): boolean =>
  window.location.pathname === root || getCurrentTermId() === termId

// 期間切り替え時の遷移先
export const generateChangeTermFallback = (termId: string): string => generateTrees({ termId })

// generatePathの:termIdを必要に応じてcurrentTagに置き換える版
export const generatePathWithCurrentTerm = (
  path: string,
  params: { termId: string } & Record<string, string>,
): string =>
  isInCurrentTerm(params.termId)
    ? generatePath(path.replace(':termId', currentTag), { ...params, termId: undefined })
    : generatePath(path, params)

// Root
export const root = '/'
export const generateRoot = (): string => root

// Authenticate
export const signIn = '/signin'
export const signUp = '/signup'
export const samlLoginSuccess = '/saml/loginSuccess'
export const loginWithGoogle = `${config.base}/login/google`
export const signUpWithGoogle = `${config.base}/sign_up/google`
export const enableGoogleOAuthLogin = `${config.base}/link/google`

// Password
export const passwordReset = '/users/password/reset'
export const passwordResetConfirm = '/users/password/confirmation'
export const emailResetConfirm = '/users/email/confirmation'

// Account Setup
export const accountSetup = '/account/setup'
export const accountSetupComplete = '/account/setup/complete'

// Error
export const notFound = '/notfound'

// Term
export const treesInCurrentTerm = `/terms/${currentTag}/okrs` as const
export const term = '/terms/:termId'
export const generateTerm = (termId: string): string =>
  generatePathWithCurrentTerm(term, { termId })

// OKR Tree
export const trees = '/terms/:termId/okrs'
export const MAP_PAGE_TREE_VIEW_QUERY = 'tree'
export const MAP_PAGE_LIST_VIEW_QUERY = 'list'
export const MAP_PAGE_ACTIVITY_VIEW_QUERY = 'activity'
export const MAP_VIEW_QUERY_PARAMS = 'view'
export const MAP_VIEW_LIST = [
  MAP_PAGE_TREE_VIEW_QUERY,
  MAP_PAGE_LIST_VIEW_QUERY,
  MAP_PAGE_ACTIVITY_VIEW_QUERY,
] as const
export type MapView = typeof MAP_VIEW_LIST[number]
export const OKR_LIST_TAB_QUERY = 'okr-list' as const
export const OKR_LIST_TAB = ['self', 'group', 'all'] as const
export type OkrMapType = typeof OKR_LIST_TAB[number]

// Mapビューか判定
export const isMapView = (v: unknown): v is MapView => MAP_VIEW_LIST.includes(v as MapView)

type WithMapFilterParamArg = {
  groupIds?: ReadonlyArray<string> // グループフィルタ用のパラメータ
  userIds?: ReadonlyArray<string> // ユーザーフィルタ用のパラメータ
  nodeId?: string // このノードIDを元にセンタリングを行います
  [OKR_LIST_TAB_QUERY]?: string
} & DrawerQuery
// termIDをセットしMap用のURLを生成
export const generateTrees = ({
  termId,
  groupIds,
  userIds,
  nodeId,
  [DRAWER_QUERY_TYPE]: drawerType,
  [DRAWER_QUERY_TAB]: drawerTab,
  [DRAWER_QUERY_ID]: drawerId,
  [OKR_LIST_TAB_QUERY]: mapType,
}: { termId: string } & WithMapFilterParamArg): string => {
  // Map上でのみ利用するクエリパラメータ
  const queryParams = new window.URLSearchParams()
  if (groupIds && groupIds.length > 0) {
    groupIds.forEach((v) => queryParams.append('group', v))
  }
  if (userIds && userIds.length > 0) {
    userIds.forEach((v) => queryParams.append('user', v))
  }
  if (mapType != null) {
    queryParams.append(OKR_LIST_TAB_QUERY, mapType)
  }

  // ドロワー
  if (drawerType != null) {
    queryParams.append(DRAWER_QUERY_TYPE, drawerType)
  }
  if (drawerTab != null) {
    queryParams.append(DRAWER_QUERY_TAB, drawerTab)
  }
  if (drawerId != null) {
    queryParams.append(DRAWER_QUERY_ID, drawerId)
  }

  // フラグメント
  const fragments = new window.URLSearchParams()
  if (nodeId) {
    fragments.append('nodeId', nodeId)
  }

  const queryString = Array.from(queryParams).length > 0 ? `?${queryParams.toString()}` : ''
  const fragmentString = Array.from(fragments).length > 0 ? `#${fragments.toString()}` : ''
  return `${generatePathWithCurrentTerm(trees, { termId })}${queryString}${fragmentString}`
}

// MapリストビューのURLを生成
export const generateOkrListView = (termId: string, okrMapType: OkrMapType): string => {
  const queryParams = new window.URLSearchParams()
  queryParams.append(OKR_LIST_TAB_QUERY, okrMapType)
  queryParams.append(MAP_VIEW_QUERY_PARAMS, MAP_PAGE_LIST_VIEW_QUERY)
  return `${generatePathWithCurrentTerm(trees, {
    termId,
  })}?${queryParams}`
}

// @deprecated 古いMapβの定義
export const deprecatedExperimentalTrees = '/terms/:termId/beta/trees'

// @deprecated 古いMap
export const deprecatedTrees = '/terms/:termId/trees'
export const deprecatedTree = '/trees/:nodeId'
export const deprecatedNode = '/terms/:termId/nodes/:nodeId'
export const deprecatedSubtree = '/terms/:termId/subtrees/:nodeId'
// @deprecated 古いMap
export const generateDeprecatedTrees = (termId: string): string =>
  generatePathWithCurrentTerm(deprecatedTrees, { termId })

// OKR
export const okr = '/okrs/:nodeId'
export const generateOkr = (nodeId: string): string => generatePath(okr, { nodeId })

export const OKR_MEETING_HASH = '#okr-meeting'
export const CHECKIN_SUMMARY_HASH = '#checkin-summary'

// Objective
export const objective = '/okrs/:nodeId/objectives/:objectiveId'
export const objectiveEdit = '/okrs/:nodeId/objectives/:objectiveId/edit'
export const generateObjective = (nodeId: string, objectiveId: string): string =>
  generatePath(objective, { nodeId, objectiveId })
export const generateObjectiveEdit = (nodeId: string, objectiveId: string): string =>
  generatePath(objectiveEdit, { nodeId, objectiveId })
const prefixObjectiveHash = 'o-'
export const matchObjectiveDomId = new RegExp(`^${prefixObjectiveHash}.+$`)
export const generateObjectiveHash = (objectiveId: string): string =>
  `${prefixObjectiveHash}${objectiveId}`

// Key Result
export const keyResult = '/okrs/:nodeId/key-results/:keyResultId'
export const keyResultEdit = '/okrs/:nodeId/key-results/:keyResultId/edit'
export const generateKeyResult = (nodeId: string, keyResultId: string): string =>
  generatePath(keyResult, { nodeId, keyResultId })
export const generateKeyResultEdit = (nodeId: string, keyResultId: string): string =>
  generatePath(keyResultEdit, { nodeId, keyResultId })
export const prefixKeyResultHash = 'kr-'
export const matchKeyResultDomId = new RegExp(`^${prefixKeyResultHash}.+$`)
export const generateKeyResultHash = (keyResultId: string): string =>
  `${prefixKeyResultHash}${keyResultId}`

// Action Plan
export const actionPlans = '/okrs/:okrNodeId/key-results/:keyResultId/action-plans'
export const actionPlan = '/okrs/:okrNodeId/key-results/:keyResultId/action-plans/:actionPlanId'
export const actionPlanEdit =
  '/okrs/:okrNodeId/key-results/:keyResultId/action-plans/:actionPlanId/edit'
export const generateActionPlans = (okrNodeId: string, keyResultId: string): string =>
  generatePath(actionPlans, { okrNodeId, keyResultId })
export const generateActionPlan = (
  okrNodeId: string,
  keyResultId: string,
  actionPlanId: string,
): string => generatePath(actionPlan, { okrNodeId, keyResultId, actionPlanId })
export const generateActionPlanEdit = (
  okrNodeId: string,
  keyResultId: string,
  actionPlanId: string,
): string => generatePath(actionPlanEdit, { okrNodeId, keyResultId, actionPlanId })

// CheckinSummary
export const checkinSummary = '/okrs/:okrNodeId/checkin-summaries/:checkinSummaryId'
export const generateCheckinSummary = (okrNodeId: string, checkinSummaryId: string): string =>
  generatePath(checkinSummary, { okrNodeId, checkinSummaryId })
export const checkinSummaryEdit = '/okrs/:okrNodeId/checkin-summaries/:checkinSummaryId/edit'
export const generateCheckinSummaryEdit = (okrNodeId: string, checkinSummaryId: string): string =>
  generatePath(checkinSummaryEdit, { okrNodeId, checkinSummaryId })

// Note
export const note = '/notes/:noteId'
export const noteEdit = '/notes/:noteId/edit'
export const noteLabels = '/noteLabels'
export const labeledNotes = '/terms/:termId/note-labels/:noteLabelId/notes'
export const generateNote = (noteId: string): string => generatePath(note, { noteId })
export const generateNoteEdit = (noteId: string): string => generatePath(noteEdit, { noteId })
export const generateLabeledNoteList = (termId: string, noteLabelId: string): string =>
  generatePathWithCurrentTerm(labeledNotes, { termId, noteLabelId })

// Personal Note
export const personalNote = '/personal-notes/:personalNoteId'
export const personalNoteEdit = '/personal-notes/:personalNoteId/edit'
export const personalNoteLabels = '/personalNoteLabels'
export const labeledPersonalNotes = '/personal-note-labels/:personalNoteLabelId/notes'
export const generatePersonalNote = (personalNoteId: string): string =>
  generatePath(personalNote, { personalNoteId })
export const generatePersonalNoteEdit = (personalNoteId: string): string =>
  generatePath(personalNoteEdit, { personalNoteId })
export const generateLabeledPersonalNoteList = (personalNoteLabelId: string): string =>
  generatePath(labeledPersonalNotes, { personalNoteLabelId })

// Group
export const groupsSettingAdmin = '/admin/groups'
export const groupsImportAdmin = '/admin/groups/import'
export const group = '/groups/:groupId'
export const generateGroup = (groupId: string): string => generatePath(group, { groupId })

// User
export const user = '/terms/:termId/users/:userId'
export const users = '/terms/:termId/users'
export const userInCurrentTerm = `/terms/${currentTag}/users/:userId` as const
export const usersInCurrentTerm = `/terms/${currentTag}/users` as const
export const meInCurrentTerm = `/terms/${currentTag}/users/me` as const
export const generateUsers = (termId: string): string =>
  generatePathWithCurrentTerm(users, { termId })
export const generateUserInTerm =
  (termId: string) =>
  (userId: string): string =>
    generatePathWithCurrentTerm(user, { termId, userId })
export const generateUser = (userId: string): string => generatePath(userInCurrentTerm, { userId })

// CheckinModal
export const CHECKIN_MODAL_QUERY = 'b-u-modal' as const
export const makeCheckinSearchParams = (
  isOpened: boolean,
  fromDrawer?: boolean,
): URLSearchParams => {
  const searchParams = new window.URLSearchParams(window.location.search)
  searchParams.set(CHECKIN_MODAL_QUERY, isOpened.toString())
  // ドロワーからの訪問のときは、チェックインモーダル裏のドロワーを閉じる
  if (fromDrawer) {
    searchParams.delete(DRAWER_QUERY_TAB)
  }
  return searchParams
}
export const getCheckinModalQuery = (): { [CHECKIN_MODAL_QUERY]: boolean } => {
  const url = new URL(window.location.href)
  return {
    [CHECKIN_MODAL_QUERY]: isTrueString(url.searchParams.get(CHECKIN_MODAL_QUERY)),
  }
}

// Home
export const betaHomeRootForRedirect = `/beta${user}/*` as const
export const myHome = meInCurrentTerm
export const home = user
export const homeOkr = `${home}/okr` as const
export const homeActionPlan = `${home}/action-plan` as const
export const homeNote = `${home}/note` as const
export const homeActivity = `${home}/activity` as const
export const generateHome =
  (termId: string) =>
  (userId: string): string =>
    generatePathWithCurrentTerm(home, { termId, userId })
export const generateHomeOkr =
  (termId: string) =>
  (userId: string): string =>
    generatePathWithCurrentTerm(homeOkr, { termId, userId })
export const generateHomeActionPlan =
  (termId: string) =>
  (userId: string): string =>
    generatePathWithCurrentTerm(homeActionPlan, { termId, userId })
export const generateHomeNote =
  (termId: string) =>
  (userId: string): string =>
    generatePathWithCurrentTerm(homeNote, { termId, userId })
export const generateHomeActivity =
  (termId: string) =>
  (userId: string): string =>
    generatePathWithCurrentTerm(homeActivity, { termId, userId })

// Notification
export const notifications = '/notifications'

// Setting
export const setting = '/setting'
export const accountSetting = '/setting/account'

// One on One Drawer Query
export const ONE_ON_ONE_DRAWER_QUERY_TERM_ID = 'o-d-term-id' as const
export const ONE_ON_ONE_DRAWER_QUERY_USER_ID = 'o-d-user-id' as const
export const ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID = 'o-d-one-on-one-id' as const
type OneOnOneDrawerQuery = {
  [key in
    | typeof ONE_ON_ONE_DRAWER_QUERY_TERM_ID
    | typeof ONE_ON_ONE_DRAWER_QUERY_USER_ID
    | typeof ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID]?: string
}

export const oneOnOne = '/one-on-ones'
export const oneOnOneDetail = '/one-on-ones/:oneOnOneId/meetings/:oneOnOneMeetingId'
export const oneOnOneHistory = '/one-on-ones/:oneOnOneId/history'
export const PROCCEDING_MEMO = 'proccedingMemo'
export const PRIVATE_MEMO = 'privateMemo'
export const MEMO_VIEW_LIST = [PROCCEDING_MEMO, PRIVATE_MEMO] as const
export type MemoView = typeof MEMO_VIEW_LIST[number]

export const ASSIGNED_OKR = 'assignOkr'
export const BULLETIN_BOARD = 'bulletinBoard'
export const BOARD_VIEW_LIST = [ASSIGNED_OKR, BULLETIN_BOARD] as const
export type BoardView = typeof BOARD_VIEW_LIST[number]

export const generateOneOnOne = (): string => generatePath(oneOnOne)

export const generateOneOnOneDetail = ({
  oneOnOneId,
  oneOnOneMeetingId,
  [ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID]: drawerOneOnOneId,
  [ONE_ON_ONE_DRAWER_QUERY_USER_ID]: drawerUserId,
  [ONE_ON_ONE_DRAWER_QUERY_TERM_ID]: drawerTermId,
}: {
  oneOnOneId: string
  oneOnOneMeetingId: string
} & OneOnOneDrawerQuery): string => {
  const queryParams = new window.URLSearchParams()
  if (drawerOneOnOneId) {
    queryParams.append(ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID, drawerOneOnOneId)
  }

  if (drawerUserId) {
    queryParams.append(ONE_ON_ONE_DRAWER_QUERY_USER_ID, drawerUserId)
  }

  if (drawerTermId) {
    queryParams.append(ONE_ON_ONE_DRAWER_QUERY_TERM_ID, drawerTermId)
  }

  const queryString = Array.from(queryParams).length > 0 ? `?${queryParams.toString()}` : ''
  return `${generatePath(oneOnOneDetail, { oneOnOneId, oneOnOneMeetingId })}${queryString}`
}

export const generateOneOnOneHistory = (oneOnOneId: string): string =>
  `${generatePath(oneOnOneHistory, { oneOnOneId })} `

// Admin
export const admin = '/admin'
export const organizationAdmin = '/admin/organization'
export const okrTermsAdmin = '/admin/okr-terms'
export const okrGlossaries = '/admin/okr-glossaries'
export const usersAdmin = '/admin/users'
export const usersImportAdmin = '/admin/users/import'
export const userAdmin = '/admin/users/:userId'
export const oneOnOneAdmin = '/admin/one-on-one'
export const createFixedAgendaAdmin = '/admin/one-on-one/fixed-agenda/register'
export const editFixedAgendaAdmin = '/admin/one-on-one/fixed-agendas/:agendaId/edit'
export const integrationsAdmin = '/admin/integrations'
export const integrationsSmartHREmployees = '/admin/integrations/smarthr'
export const smarthrHistories = '/admin/integrations/smarthr/histories'
export const security = '/admin/security'
export const ipWhitelistAdmin = '/admin/ip-whitelist'
export const samlAdmin = '/admin/saml'
export const oauthWhitelistAdmin = '/admin/oauth-whitelist'
export const billingAdmin = '/admin/billing'
export const adminDefault = organizationAdmin
export const generateAdminUser = (userId: string): string => generatePath(userAdmin, { userId })
export const generateAdminOkrTerms = (): string => okrTermsAdmin
export const generateAdminCreateFixedAgenda = (): string => createFixedAgendaAdmin
export const generateAdminEditFixedAgenda = (agendaId: string): string =>
  generatePath(editFixedAgendaAdmin, { agendaId })

// template
export const templateAdmin = '/admin/template'

// CheckinModal Query
export const CHECKIN_MODAL = 'b-u-modal' as const
export const generateUserWithCheckinModalOpen = (userId: string): string =>
  `${generateUser(userId)}?${CHECKIN_MODAL}=true`

// Drawer Query
export const DRAWER_QUERY_TAB = 'd-tab' as const
export const DRAWER_QUERY_ID = 'd-id' as const
export const DRAWER_QUERY_TYPE = 'd-type' as const
export type DrawerQuery = {
  [DRAWER_QUERY_TYPE]?: DrawerType
  [DRAWER_QUERY_TAB]?: DrawerTab
  [DRAWER_QUERY_ID]?: string
}

export const makeDrawerSearchParams = (
  type: DrawerType,
  tab: DrawerTab,
  id: string,
): URLSearchParams => {
  const searchParams = new window.URLSearchParams()
  searchParams.set(DRAWER_QUERY_TYPE, type)
  searchParams.set(DRAWER_QUERY_TAB, tab)
  searchParams.set(DRAWER_QUERY_ID, id)

  return searchParams
}

export const getDrawerQuery = (): DrawerQuery => {
  const url = new URL(window.location.href)
  return {
    [DRAWER_QUERY_TYPE]: (url.searchParams.get(DRAWER_QUERY_TYPE) || undefined) as
      | DrawerType
      | undefined,
    [DRAWER_QUERY_TAB]: (url.searchParams.get(DRAWER_QUERY_TAB) || undefined) as
      | DrawerTab
      | undefined,
    [DRAWER_QUERY_ID]: url.searchParams.get(DRAWER_QUERY_ID) || undefined,
  }
}

// @deprecated 古いMap
export type MapTransform = { x: number; y: number; z: number }
// @deprecated 古いMap
export type MapDisplayParams = {
  center?: string
  groups?: ReadonlyArray<string>
  users?: ReadonlyArray<string>
  nodes?: ReadonlyArray<string>
  folds?: ReadonlySet<string>
  transform?: MapTransform
  view?: MapView
}
// @deprecated 古いMap
export const generateFilteredTreeUrl = (
  pathname: string,
  {
    center,
    folds,
    groups,
    users: usersDP,
    nodes,
    transform,
    view,
    [DRAWER_QUERY_TYPE]: dType,
    [DRAWER_QUERY_TAB]: dTab,
    [DRAWER_QUERY_ID]: dId,
  }: MapDisplayParams & Partial<DrawerQuery>,
): string => {
  const chunks = [pathname]
  if (
    center ||
    (groups && groups.length > 0) ||
    (folds && folds.size > 0) ||
    (usersDP && usersDP.length > 0) ||
    (nodes && nodes.length > 0) ||
    dTab ||
    dId ||
    dType
  ) {
    const searchParams = new window.URLSearchParams()
    if (center) {
      searchParams.set('center', center)
    }
    if (groups && groups.length > 0) {
      groups.forEach((v) => searchParams.append('group', v))
    }
    if (usersDP && usersDP.length > 0) {
      usersDP.forEach((v) => searchParams.append('user', v))
    }
    if (nodes && nodes.length > 0) {
      nodes.forEach((v) => searchParams.append('node', v))
    }
    if (folds && folds.size > 0) {
      folds.forEach((id) => searchParams.append('fold', id))
    }
    if (view) {
      searchParams.set(MAP_VIEW_QUERY_PARAMS, view)
    }
    if (dTab) {
      searchParams.set(DRAWER_QUERY_TAB, dTab)
    }
    if (dId) {
      searchParams.set(DRAWER_QUERY_ID, dId)
    }
    if (dType) {
      searchParams.set(DRAWER_QUERY_TYPE, dType)
    }
    chunks.push(`?${searchParams.toString()}`)
  } else if (view) {
    const searchParams = new window.URLSearchParams()
    searchParams.set(MAP_VIEW_QUERY_PARAMS, view)
    chunks.push(`?${searchParams.toString()}`)
  }

  if (transform) {
    chunks.push(`#${transform.x},${transform.y},${transform.z}`)
  }
  return chunks.join('')
}
